import React from "react";

const OrderModal = ({ children }) => {
  return (
    <div className="fixed inset-0 overflow-scroll scrollbar-hide z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white m-4 rounded-lg shadow-lg max-h-full overflow-y-auto scrollbar-hide">
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default OrderModal;
