import React from 'react';
import SelectionBox from '../../components/feature/selection_checkbox';
import { WeightGain, WeightLoss } from '../../assets/icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { addInformation, validateInformation } from '../../redux/actions';
import { CustomInput } from '../../components/common/custom_input';
import { useTranslation } from 'react-i18next';


const Form = (props) => {
  const weight = useSelector(state => state.mealPrep.weight)
  const height = useSelector(state => state.mealPrep.height)
  const range = useSelector(state => state.mealPrep.range)
  const target = useSelector(state => state.mealPrep.target)
  const error = useSelector(state => state.mealPrep.error)
  const { t }=useTranslation() 
  const dispatch = useDispatch();

  const handleInformation = (info, Infotype) => {
    dispatch(addInformation(info, Infotype))
  }
  const validate = (weight, height, target) => {
    if (weight === '') {
      dispatch(validateInformation('weight cannot be empty'))
      console.log('weight no empty')
    } else {
      if (height === '') {
        dispatch(validateInformation('height cannot be empty'))
        console.log('height no empty')
      } else {
        if (target === '') {
          dispatch(validateInformation('select your target'))
          console.log('target no empty')
        }
        else {
          props.handleNextStep()
          dispatch(validateInformation(''))
          return true
        }
      }
    }
  }
  return (
    <div className="w-fit h-full p-8">
      <h1 className="text-center font-bold md:text-2xl text-lg text-gray-600">
        {t("tellUs")}
      </h1>
      <h1 className="text-center font-light  md:text-lg text-base text-gray-600">
        {t("tellUsDesc")}
      </h1>
      {error && (
        <p className="text-center font-semibold text-sm text-red-600">
          Provide all the information to proceed
        </p>
      )}
      <div className="w-full flex flex-col justify-center items-center md:flex-row gap-3 mt-8">
        <CustomInput
          target={weight}
          error={error}
          following="KG"
          placeholder={t("yourWeight")}
          type="weight"
          handleInformation={handleInformation}
        />
        <CustomInput
          target={height}
          error={error}
          following="M"
          placeholder={t("yourHeight")}
          type="height"
          handleInformation={handleInformation}
        />
      </div>
      <div>
        <h1 className="text-center font-bold md:text-2xl text-lg text-gray-600 mt-10">
          {t("range")}
        </h1>
        <h1 className="text-center md:text-lg text-base font-light text-gray-600">
          {t("rangeDesc")}
        </h1>
        <div className="w-full">
          <input
            type="range"
            min={0}
            max={100}
            value={range}
            onChange={(e) => {
              handleInformation(e.target.value, "range");
            }}
            className="w-full text-primary"
            style={{
              accentColor: "#fadb0d",
              appearance: "none",
              height: "15px",
              background: "#ddd",
              cursor: "pointer",
              color: "#fadb0d",
              outline: "none",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          />
          <div className="flex justify-between mt-2">
            <span className="text-sm text-gray-500">{t("easy")}</span>
            <span className="text-sm text-gray-500">{t("intermediate")}</span>
            <span className="text-sm text-gray-500">{t("hard")}</span>
          </div>
          <div className="flex justify-between mt-2">
            <span className="text-sm text-gray-500">0</span>
            <span className="text-sm text-gray-500">50</span>
            <span className="text-sm text-gray-500">100</span>
          </div>
          <div className="flex justify-center mt-4">
            <div className="p-2 md:text-base text-sm rounded-md bg-secondary flex items-center justify-center text-slate-900">
              {range <= 33 && (
                <span>
                  {t("easy")} {handleInformation("Easy", "rangeValue")}
                </span>
              )}
              {range > 33 && range <= 66 && (
                <span>
                  {t("intermediate")}{" "}
                  {handleInformation("Intermediate", "rangeValue")}
                </span>
              )}
              {range > 66 && (
                <span>
                  {t("hard")} {handleInformation("Hard", "rangeValue")}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <h1 className="text-center font-bold md:text-2xl text-lg text-gray-600 mt-10">
        {t("target")}
      </h1>
      <h1 className="text-center  font-light md:text-lg text-base text-gray-600">
        {t("targetDesc")}
      </h1>
      <section className="w-full flex mt-6">
        <SelectionBox
          error={error}
          Selected={target === "Weight loss"}
          PlanName={t("weightLoss")}
          Icon={<WeightLoss />}
          OnClick={() => handleInformation("Weight loss")}
        />
        <SelectionBox
          error={error}
          Selected={target === "Weight gain"}
          PlanName={t("weightGain")}
          Icon={<WeightGain />}
          OnClick={() => handleInformation("Weight gain")}
        />
      </section>
      <button
        className="px-4 md:text-base text-xs w-full h-12 mt-7 py-2 bg-primary text-white font-bold rounded-md hover:bg-primaryDarker transition-all"
        onClick={() => validate(weight, height, target)}
      >
        {t("next")}
      </button>
    </div>
  );
};

export default Form;
