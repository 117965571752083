import { useState } from 'react';
import { Link } from 'react-router-dom';
import wordmarkLogo from '../../../assets/logos/Logo_wordmark.png'
import LanguageSelector from '../../feature/LanguageSelector';
import { useTranslation } from 'react-i18next';

const MobileNavigationBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="bg-white block overflow-hidden sm:hidden text-gray-900 shadow-md fixed p-4 top-0 w-full z-40">
      <nav className="flex justify-between items-center py-2 px-4">
        <Link to="/" className="font-bold text-lg">
          <img className="w-20" src={wordmarkLogo} alt="" />
        </Link>
        <div className="flex items-center">
          <LanguageSelector />
          <button
            onClick={handleMenuToggle}
            className={`focus:outline-none ml-4 transition-colors duration-300 ${
              menuOpen ? "text-green-600" : "text-gray-900"
            }`}
          >
            <svg
              className={`w-6 h-6 ${
                menuOpen
                  ? "transform rotate-95 transition-all duration-150"
                  : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </nav>
      {menuOpen && (
        <ul className="flex flex-col items-center space-y-2 py-2 px-4">
          <li>
            <Link to="/mealplan" className="hover:text-green-600 nav">
              {t("mealPlan")}
            </Link>
          </li>
          <li>
            <Link to="/mealprep" className="hover:text-green-600 nav">
              {t("mealPrep")}
            </Link>
          </li>
          <li>
            <a href="#how_it_works" className="nav">
              {t("howItWorks")}
            </a>
          </li>
          <li>
            <Link
              to="/mealprep"
              className="px-2 py-1 bg-green-600 text-white font-bold rounded-full hover:bg-green-400"
            >
              {t("getStarted")}
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default MobileNavigationBar;
