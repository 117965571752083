

function copyText() {
  const textToCopy = document.getElementById('textToCopy').textContent;

  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        showNotifier();
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  } else {
    fallbackCopyTextToClipboard(textToCopy);
  }
}

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      showNotifier();
    } else {
      console.error('Fallback copy text failed.');
    }
  } catch (error) {
    console.error('Fallback copy text error: ', error);
  }

  document.body.removeChild(textArea);
}

function showNotifier() {
  const notifier = document.getElementById('notifier');
  notifier.classList.add('show');
  setTimeout(() => {
    notifier.classList.remove('show');
  }, 2000);
}


export default copyText