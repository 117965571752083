import axios from "axios";
import { sendOrder } from "../redux/actions";

const SendMealPlanOrderToApi = async (
  fullName,
  phoneNumber,
  address,
  other,
  days,
  totalPrice,
  schedule,
  content,
  mealType,
  LoadingTrue,
  LoadingFalse,
  handleError,
  dispatch
) => {
  const selectedDays = days.join(",");
  const formattedPrice = totalPrice.toLocaleString();
  const phoneFormatted = "+251" + phoneNumber;

  const orderData = JSON.stringify({
    Full_Name: fullName,
    Phone_Number: phoneFormatted,
    Place_of_Delivery: address,
    Allergy: other,
    Fasting_type: selectedDays,
  });

  var customer_config = {
    method: "post",
    url: "https://api.maednutritions.com/api/Customer",
    headers: {
      "Content-Type": "application/json",
    },
    data: orderData,
  };
  LoadingTrue();
  try {
    const response = await axios(customer_config);

    const mealPlanData = JSON.stringify({
      Customer_id: response.data,
      Meal_type: mealType,
      Subscription: schedule,
      Meal_PerDay: content,
      Total_Price: formattedPrice,
    });

    var sendOrderToApi = {
      method: "post",
      url: "https://api.maednutritions.com/api/Meal_Plan",
      headers: {
        "Content-Type": "application/json",
      },
      data: mealPlanData,
    };

    await axios(sendOrderToApi);
    await dispatch(
      sendOrder(mealType, fullName, phoneNumber, address, totalPrice)
    );
    LoadingFalse();
  } catch (error) {
    LoadingFalse();
    handleError();
  }
};
export default SendMealPlanOrderToApi;
