import React from 'react'
import Cards from '../containers/cards_container'
// import logo from '../assets/logos/logo_transparent.png';
import food from '../assets/2.png';
import { useTranslation } from 'react-i18next';

const How = () => {
    const { t }=useTranslation()
    return (
      <div
        className="flex flex-col gap-3 items-center text-center md:mt-10 mt-20 relative w-full p-4 sm:p-8 md:p-14 lg:p-20 xl:p-24"
        id="how_it_works"
      >
        <h1 className="font-medium text-4xl text-gray-700">
          {t("howItWorksTitle")}{" "}
          <span className="text-primary">{t("work")} </span>
          <span className="text-secondary">?</span>
        </h1>
        {/* <img className='absolute drop-shadow-lg w-24 rotate-12 right-0 left-0 -z-50 ' src={logo} alt="" /> */}
        <p className="font-light w-full sm:w-2/3 xl:w-1/2 text-xl p-4 text-gray-700">
          {t("howItWorksDesc")}
        </p>
        <img
          className="absolute w-44 mix-blend-overlay md:left-40 left-0 -top-[5.5%] right-50 -z-10  md:top-10 transition-all "
          src={food}
          alt=""
        />
        <Cards />
      </div>
    );
}

export default How