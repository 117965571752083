import { InputValidator } from './InputValidator';

export function validator(formData, setErrors) {
    return () => {
        if (!InputValidator.validateName(formData.name)) {
            setErrors((data) => ({
                ...data,
                nameError: 'Full name must be between 1 and 50 characters.'
            }));
            return false;
        }
        if (!InputValidator.validatePhoneNumber(formData.phoneNumber)) {
            setErrors((data) => ({
                ...data,
                nameError: ''
            }));
            setErrors((data) => ({
                ...data,
                phoneNumberError: 'Phone number must be 8 digits and contain only numbers.'
            }));
            return false;
        }
        if (!InputValidator.validateAddress(formData.address)) {
            setErrors((data) => ({
                ...data,
                phoneNumberError: ''
            }));
            setErrors((data) => ({
                ...data,
                addressError: 'Address cannot be empty.'
            }));
            return false;
        }
        setErrors((data) => ({
            ...data,
            nameError: ''
        }));
        setErrors((data) => ({
            ...data,
            addressError: ''
        }));
        return true;
    };
}
