import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OrderModal from '../components/modals/order_modal';
import { CopyIcon, InstagramIcon, SuccessIcon, TelegramIcon, TikTokIcon } from '../assets/icons/Icons';
import { orderDone } from '../redux/actions';
import copyText from '../utils/copyText';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import generatePDF from '../utils/generatePdf';

const OrderInfo = () => {

  const showInfo = useSelector(state => state.order.showInfo);
  const { t }=useTranslation()
  // const fullName = useSelector(state => state.order.fullName);
  // const phoneNumber = useSelector(state => state.order.phoneNumber);
  // const address = useSelector(state => state.order.address);
  // const totalPrice = useSelector(state => state.order.totalPrice);
  const dispatch = useDispatch();
  const handleOrderDone = () => {
    dispatch(orderDone());
  }
  // const sentOrder=useSelector(state=>state.order.sentOrder);
  // const report=[
  //   {
  //     headline: 'Order by',
  //     body: fullName
  //   },
  //   {
  //     headline: 'Meal Plan',
  //     body: sentOrder.name
  //   },
  //   {
  //     headline: 'Phone-number',
  //     body: phoneNumber
  //   },
  //   {
  //     headline: 'Address',
  //     body: address
  //   },
  //   {
  //     headline: 'Total-price',
  //     body: totalPrice.toString()+' Birr'
  //   },
  // ];
  return (
    showInfo && (
      <OrderModal>
        <div
          id="notifier"
          className="notifier flex justify-evenly items-center"
        >
          <SuccessIcon className="w-6 h-6" />
          {t("textCopied")}
        </div>
        <div className="w-full flex flex-col justify-center items-center gap-4">
          <SuccessIcon className="text-primary w-14 h-14" />
          <h1 className="text-primary text-xl">{t("orderSent")}</h1>
          <p className="text-slate-600">{t("orderSendDesc")}</p>
          <div className="flex flex-col sm:flex-row gap-4 w-full">
            <div className="flex flex-col items-center gap-2">
              <p className="font-semibold text-sm text-slate-700">{t("cbe")}</p>
              <p className="uppercase text-slate-800 font-semibold text-sm">
                Sofonias Nebiyu
              </p>
              <div
                onClick={() => copyText()}
                className="bg-slate-200 p-1 cursor-pointer font-semibold rounded-lg w-full flex justify-center items-center text-slate-800"
              >
                <p id="textToCopy">1000261529258</p>
                <span onClick={() => copyText()}>
                  <CopyIcon className="text-primary cursor-pointer rounded-full w-6 h-6 p-1 m-1 transition-all hover:text-white hover:bg-primary" />
                </span>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center sm:items-start gap-2">
              <p className="font-semibold text-sm text-slate-700">
                {t("ourContact")}
              </p>
              <div className="flex items-center gap-1">
                <TelegramIcon />
                <Link
                  target="_blank"
                  to={
                    "https://www.tiktok.com/@maednutrition?_t=8gCODNXJLeg&_r=1"
                  }
                  className="flex items-center gap-2 hover:text-primary duration-150"
                >
                  Maednutritions
                </Link>
              </div>
              <div className="flex items-center gap-1">
                <TikTokIcon />
                <Link
                  target="_blank"
                  to={
                    "https://www.tiktok.com/@maednutrition?_t=8gCODNXJLeg&_r=1"
                  }
                  className="flex items-center gap-2 hover:text-primary duration-150"
                >
                  Maednutritions
                </Link>
              </div>
              <div className="flex items-center gap-1">
                <InstagramIcon />
                <Link
                  target="_blank"
                  to={
                    "https://www.tiktok.com/@maednutrition?_t=8gCODNXJLeg&_r=1"
                  }
                  className="flex items-center gap-2 hover:text-primary duration-150"
                >
                  Maednutritions
                </Link>
              </div>
            </div>
          </div>
          {/* <p className="text-primary text-sm font-semibold capitalize">
            <span className="font-bold text-slate-800">Note:</span> Before
            closing, we highly recommend downloading the order report below.
          </p> */}
          <div className="flex flex-col sm:flex-row w-full gap-2">
            {/* <button onClick={()=>generatePDF(report,fullName)} className="flex-1 text-slate-800 gap-1 transition-all font-semibold hover:bg-primary hover:text-white flex items-center justify-center sm:justify-center border-2 border-primary p-1 rounded-lg" type="button">
      <span className="sm:inline-block">Download Report</span>
      <span className="animate-bounce"><DownloadIcon /></span>
    </button> */}
            <button
              onClick={() => handleOrderDone()}
              className="flex-1 transition-all font-semibold bg-primary hover:text-primary hover:bg-transparent text-white flex items-center justify-center border-2 border-primary p-1 rounded-lg"
              type="button"
            >
              {t("done")}
            </button>
          </div>
        </div>
      </OrderModal>
    )
  );
}

export default OrderInfo