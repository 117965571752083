import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function MealCard({ image, mealName, mealDescription, price, link }) {
  const { t }=useTranslation()
  return (
    <div className=" sm:w-80 h-1/2 sm:h-[26rem] overflow-hidden rounded-md bg-white border-gray-400 border m-6 hover:cursor-pointer hover:border-primary transition-all">
      <Link to={link}>
        <img className="object-cover w-full h-52" src={image} alt="" />
        <div className="p-3">
          <div className="flex pb-2 w-full justify-between">
            <h1 className="font-bold text-gray-800 w-1/2 text-base sm:text-lg">
              {mealName}
            </h1>
            <p className="font-bold text-gray-800 h-fit text-base sm:text-base">
              {price} {t("birr")}/
              <span className="text-gray-600 text-sm font-normal">
                {" "}
                {t("permeal")}
              </span>
            </p>
          </div>
          <p className="text-sm text-gray-500 overflow-hidden">
            {mealDescription + "..."}
          </p>
        </div>
      </Link>
    </div>
  );
}
export default MealCard
