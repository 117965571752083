import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MealPrepCard from '../../components/feature/MealPrepCard'
import { selectMealPrep } from '../../redux/actions'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Form3 = (props) => {
  const meals = useSelector(state => state.meals.mealList)
  const selectedMeal = useSelector(state => state.mealPrep.meal)
  const { t }=useTranslation() 
  const dispatch = useDispatch()

  const handleAddMeal = (meal) => {
    dispatch(selectMealPrep(meal))
  }
  return (
    <div>
      <h1 className="text-center font-bold md:text-2xl text-lg text-gray-600">
        {t("select")}
      </h1>
      <h1 className="text-center font-light md:text-base text-lg text-gray-600">
        {t("selectDesc")}
      </h1>
      <div className="flex justify-center w-fit flex-wrap items-center h-full p-2">
        {meals.map((meal) => (
          <div key={meal.id} onClick={() => handleAddMeal(meal)}>
            <MealPrepCard
              mealName={t(meal.name)}
              mealDescription={t(meal.shortDesc)}
              price={meal.price}
              image={meal.image[0]}
              Selected={selectedMeal.id === meal.id}
            />
          </div>
        ))}
        <div className="flex w-5/6 justify-between gap-5 md:text-base text-xs">
          <button
            className="px-4 w-full mt-7 h-12 py-2 bg-primary text-white font-bold rounded-md hover:bg-primaryDarker transition-all"
            onClick={props.handlePrevStep}
          >
            {t("backbtn")}
          </button>
          <Link
            to={`/mealPrepdetail/${selectedMeal.id}`}
            className="px-4 flex text-center items-center justify-center w-full mt-7 h-12 py-2 bg-primary text-white font-bold rounded-md hover:bg-primaryDarker transition-all"
          >
            {t("confirm")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Form3