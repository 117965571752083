// PCNavigationBar.jsx
import { useState } from 'react';
import { Link } from 'react-router-dom';
import wordmarkLogo from '../../../assets/logos/Logo_wordmark.png'
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../feature/LanguageSelector';


const PCNavigationBar = () => {
  const { t } = useTranslation();
  const [extended, setExtended] = useState(false);
  
  const handleExtended = () => {
    setExtended(!extended);
  }

  return (
    <div
      className={`hidden bg-white fixed z-40 text-gray-900 shadow-md  top-0 w-full sm:flex flex-col  transition-all duration-300  ${
        extended ? "h-[20%]" : "h-20"
      }`}
    >
      <nav className="hidden sm:flex justify-between px-8 items-center p-5">
        <ul className="flex items-center space-x-4">
          <li>
            <Link to="/" className="font-bold text-lg ">
              <img className="w-24" src={wordmarkLogo} alt="" />
            </Link>
          </li>
          <li>
            <div className=" font-bold nav" onClick={handleExtended}>
              {t("menu")}
            </div>
          </li>
          <li>
            <a href="#how_it_works" className="nav">
              {t("howItWorks")}
            </a>
          </li>
        </ul>
        <ul className="flex items-center space-x-4">
          <li className="flex gap-5 items-center">
            <LanguageSelector />
          </li>
          <li>
            <Link
              to="/mealprep"
              className="px-4 py-2 w-auto bg-primary text-white font-bold rounded-full hover:bg-primaryDarker"
            >
              {t("getStarted")}
            </Link>
          </li>
        </ul>
      </nav>
      <ul className="hidden sm:flex items-center  ml-36 overflow-hidden">
        <li>
          <Link to="/mealplan" className="hover:text-green-600 nav">
            {t("mealPlan")}
          </Link>
        </li>
        <li>
          <Link to="/mealprep" className="hover:text-green-600 nav">
            {t("mealPrep")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default PCNavigationBar;
