import React from 'react'
import { MailIcon, PhoneIcon } from '../../assets/icons/Icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t }=useTranslation()
  return (
    <footer>
      <div className="w-full bg-slate-800 border-gray-400 flex sm:flex-nowrap flex-wrap justify-evenly gap-4 p-7">
        <ul className="flex flex-col gap-3">
          <li>
            <h1 className="font-bold text-white">{t("about")}</h1>
          </li>
          <li>
            <p className="font-light w-80 text-white">{t("aboutMaed")}</p>
          </li>
        </ul>
        <ul className="flex flex-col gap-3 font-light w-80 text-white">
          <li>
            <h1 className="font-bold text-white">{t("links")}</h1>
          </li>
          <li className="flex items-center gap-2 hover:text-primary duration-100">
            <a href="#how_it_works">{t("howItWorks")}</a>
          </li>
          <li className="flex items-center gap-2 hover:text-primary duration-100">
            <Link to="/mealprep">{t("mealPrep")}</Link>
          </li>
          <li className="flex items-center gap-2 hover:text-primary duration-100">
            <Link to="/mealplan">{t("mealPlan")}</Link>
          </li>
        </ul>
        <ul className="flex flex-col gap-3 font-light w-80 text-white">
          <li>
            <h1 className="font-bold text-white">{t("contact")}</h1>
          </li>
          <li className="flex items-center gap-2">
            <PhoneIcon />
            <a href="tel:+251993818388">+251993818388</a>
          </li>
          <li className="flex items-center gap-2">
            <PhoneIcon />
            <a href="tel:+251940256334">+251940256334</a>
          </li>
          <li className="flex items-center gap-2">
            <MailIcon />
            maednutrition@gmail.com
          </li>
        </ul>
        <ul className="flex flex-col gap-3 font-light w-80 text-white">
          <li>
            <h1 className="font-bold text-white">{t("socialMedia")}</h1>
          </li>
          <Link
            target="_blank"
            to={"https://t.me/maednutrition"}
            className="flex items-center gap-2 hover:text-primary duration-150"
          >
            Telegram
          </Link>
          <li className="flex items-center gap-2">
            <Link
              target="_blank"
              to={"https://instagram.com/maednutrition?igshid=MzRlODBiNWFlZA=="}
              className="flex items-center gap-2 hover:text-primary duration-150"
            >
              Instagram
            </Link>
          </li>
          <li className="flex items-center gap-2">
            <Link
              target="_blank"
              to={"https://www.tiktok.com/@maednutrition?_t=8gCODNXJLeg&_r=1"}
              className="flex items-center gap-2 hover:text-primary duration-150"
            >
              Tiktok
            </Link>
          </li>
        </ul>
      </div>
      <h1 className="w-full flex font-light items-center gap-2 bg-tiletBlack p-2 text-sm text-white tracking-wider justify-center">
        <Link
          to="https://tilettech.com/"
          target="_blank"
          className="hover:text-base duration-300"
        >
          {" "}
          Developed By Tilet Tech
        </Link>
      </h1>
    </footer>
  );
}

export default Footer