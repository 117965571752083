import React from 'react'
import { Link } from 'react-router-dom'
import pattern from '../assets/logos/pattern.png'
import { useTranslation } from 'react-i18next'

const GetItNow = () => {
  const { t }=useTranslation()
  return (
  <div className='h-[80%] z-20 relative overflow-hidden bg-slate-800 mt-8 flex flex-col sm:flex-row items-center justify-between'>
  <div className='p-10 sm:p-20'>
  <img className='absolute mix-blend-screen opacity-30 -z-10 top-0 w-[80%] md:w-[55%]' src={pattern} alt="" />
    <h1 className='font-bold text-white text-4xl sm:text-6xl'>
      {t('getYour')}{' '}
      <div className='text-primary font-semibold inline'>{t('meals')}</div>
      <div className='w-32 h-1 my-5 bg-secondary' />
    </h1>
    <p className='text-white mb-3 sm:mb-5 text-base sm:text-lg'>{t('getYourMealsDesc')}</p>
    <Link to='/mealprep' className='px-4 py-3 border-2 border-primary text-white font-bold rounded-full hover:bg-primaryDarker'>
      {t('getYourMealsBtn')}
    </Link>   
  </div>
  <div className='w-full sm:w-1/2 h-64 sm:h-full'>
    <div className='bg-bg-image opacity-80 w-full h-full bg-no-repeat bg-right bg-cover' />
  </div>
</div>

  )
}

export default GetItNow