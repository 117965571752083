import { combineReducers } from "@reduxjs/toolkit"
import mealPlanList from '../assets/mealPlanList'

const initialState={
 mealList:mealPlanList,
}

const OrderInitialState={
  sentOrder:[],
  order:[],
  fullName:'',
  phoneNumber:'',
  address:'',
  totalPrice:'',
  isModalOpen:false,
  showInfo:false,
  isLoading:false
}
const subscriptionInitialState={
  isSelected:false,
  subscriptionSchedule:'',
  subscriptionContent:'',
  totalCost:''
}
const mealPrepInitialState={
  weight:'',
  height:'',
  range:'',
  rangeValue:'',
  target:'',
  error:'',
  meal:[],
  isModalOpen:false
}
const mealListReducer = (state = initialState, action) => {
 return state;
};
const orderReducer=(state=OrderInitialState,action)=>
{
  switch (action.type) {
    case 'ADD_ORDER':
      return{
        ...state,
        isModalOpen:true,
        order:action.payload,
      }
    case 'CANCEL_ORDER':
      return{
        ...state,
        isModalOpen:false
      }
    case 'SEND_ORDER':
      return{
        ...state,
        isModalOpen:false,
        showInfo:true,
        sentOrder:action.payload,
        fullName:action.fullName,
        phoneNumber:action.phoneNumber,
        address:action.address,
        totalPrice:action.totalPrice
      }
    case 'ORDER_DONE':
      return{
        ...state,
        showInfo:false
      }
    default:
      return state;
  }
}
//SUBSCRIPTION RELATED REDUCER
const subscriptionReducer=(state=subscriptionInitialState,action)=>{
  switch (action.type) {
    case 'SCHEDULE_SUBSCRIPTION':
      return{
        ...state,
        subscriptionSchedule:action.payload,
        isSelected:true
      }
    case 'CONTENT_SUBSCRIPTION':
       return {
        ...state,
        subscriptionContent:action.payload,
       }
    case 'SUBSCRIPTION_PRICE':
      if(action.schedule==='1 Month')
       {
        return {
        ...state,
        totalCost:action.payload-(action.payload*5/100),
       }
      }
      if(action.schedule==='3 Months')
       {
        return {
        ...state,
        totalCost:action.payload-(action.payload*10/100),
       }
      }
      return {
        ...state,
        totalCost:action.payload,
       }
    case 'CLEAR_SUBSCRIPTION':
      return{
        ...state,
        subscriptionContent:'',
        subscriptionSchedule:'',
        isSelected:false
      }

    default:
      return state;
  }
}
//MEAL PREP REDUCER
const mealPrepReducer=(state=mealPrepInitialState,action)=>{
  switch (action.type) {
    case 'ADD_INFORMATION':
      if(action.infoType==='weight'){
        return{
          ...state,
          weight:action.payload,
      }}else if(action.infoType==='height')
      {
        return{
          ...state,
          height:action.payload,}
      }else if(action.infoType==='range')
      {
        return{
          ...state,
          range:action.payload,
        } 
      }
      else if(action.infoType==='rangeValue')
      {
        return{
          ...state,
          rangeValue:action.payload,
        } 
      }
      return{
        ...state,
        target:action.payload
      }
    case 'VALIDATE_INFORMATION':
      return{
        ...state,
        error:action.payload
      }
    case 'ADD_MEAL_PREP':
      return{
        ...state,
        isModalOpen:true,
        meal:action.payload
      }
    case 'SELECT_MEAL':
      return{
        ...state,
        meal:action.payload
      }
    default:
      return state;
  }
}

const rootReducer=combineReducers({
    meals:mealListReducer,
    order:orderReducer,
    subscription:subscriptionReducer,
    mealPrep:mealPrepReducer
})
export default rootReducer