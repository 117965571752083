import React from 'react'


const SelectionBox = ({PlanName, OnClick, Icon, Selected, error}) => {
  return (
    <div onClick={OnClick} className={
      `w-40 m-0.5 h-12 flex justify-between items-center p-1
      rounded-md  cursor-pointer
     ${Selected?' text-slate-900 bg-secondary':'bg-slate-200'}
     ${error&&'border-red-500'}
      hover:bg-secondary hover:text-slate-900`}>
        <h1 className=' font-semibold text-xs md:text-sm'>{PlanName}</h1>
        <div className='w-[20px] text-xs md:text-sm'>{Icon}</div>
    </div>
  )
}

export default SelectionBox