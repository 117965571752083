import React from 'react'
import bg from '../assets/background-1.jpg'
import bg1 from '../assets/background-2.jpg'
import mobilebg from '../assets/mobile-bg-2.jpg'
import mobilebg_1 from '../assets/mobile-bg-1.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const Home = () => {
  const { t }=useTranslation()
  return (
    <>
      <div className="relative bg-slate-800 h-screen">
        <Swiper loop={true} autoplay={{ delay: 5000 }} className="h-full">
          <SwiperSlide>
            <div
              className="absolute opacity-80 top-0 left-0 right-0 bottom-0 z-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${
                  window.innerWidth <= 640 ? mobilebg : bg
                })`,
              }}
            ></div>
            <div className="absolute top-0 left-0 right-0 bottom-10 z-10 flex flex-col items-start justify-center text-white p-5">
              <h1 className="font-bold text-4xl md:text-7xl w-full md:w-1/2">
                {t("winners")}
                <span className="text-primary">
                  {" "}
                  {t("chart")}
                  <span className="text-secondary">!</span>
                </span>
              </h1>
              <h2 className="font-normal mt-4 text-base md:text-lg lg:text-xl">
                {t("slogan1")}
              </h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${
                  window.innerWidth <= 640 ? mobilebg_1 : bg1
                })`,
              }}
            ></div>
            <div className="absolute -top-12 left-0 right-0 bottom-10 z-10 flex flex-col items-start justify-center text-white p-5">
              <h1 className="font-bold text-4xl md:text-4xl lg:text-5xl w-full md:w-1/2">
                {t("homeHeader2-1")}
                <span className="text-primary"> {t("homeHeader2-2")}</span>
                <span className="text-secondary">?</span>
              </h1>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="absolute p-5 bottom-32 md:bottom-32 z-40 left-0 right-0 flex justify-start w-full md:w-1/2 items-start gap-2 md:gap-4 my-3 md:my-4 lg:my-5">
          <Link
            to={"/mealPrep"}
            className="border-primary text-center w-full md:w-[65%] border-2 p-2 px-4 md:px-6 lg:px-8 rounded-full bg-primary text-white font-medium text-xs md:text-sm lg:text-base hover:bg-primaryDarker transition-all"
          >
            {t("mealPlanButton")}
          </Link>
          <Link
            to={"/mealPlan"}
            className="border-secondary text-center w-full md:w-[50%] border-2 p-2 px-4 md:px-6 lg:px-8 rounded-full text-white font-medium text-xs md:text-sm lg:text-base hover:bg-primaryDarker hover:text-white hover:border-primaryLight transition-all"
          >
            {t("mealPrepButton")}
          </Link>
        </div>
      </div>
    </>
    //   <div className="bg-orange-300">
    //   <div className="flex flex-col md:flex-row justify-between items-center h-full p-14">
    //     <div className="flex-1 w-full md:w-auto">
    //       <h1 className="font-medium text-3xl md:text-4xl lg:text-5xl text-gray-700">
    //         ለጤና ተስማሚ በሆነ አመጋገብ የሰውነቶን ስብ እየቀነሱ ጡንቻ መገንባት ይፈልጋሉ?
    //       </h1>
    //       <div className="my-3 md:my-4 lg:my-5" />
    //       <h2 className="font-normal text-gray-600 text-base md:text-lg lg:text-xl">
    //         የፕሮቲን መጠናቸው ከፍ ያሉ እና ኦርጋኒክ የሆኑ ምግቦች አዘጋጅተን ቤትዎ ድረስ እናቀርባለን!
    //       </h2>
    //       <div className="flex flex-col justify-start items-start gap-2 md:gap-4 my-3 md:my-4 lg:my-5">
    //         <Link to={'/mealplan'} className="border-primary text-center w-full md:w-[50%] border-2 p-2 px-4 md:px-6 lg:px-8 rounded-full bg-primary text-white font-medium text-xs md:text-sm lg:text-base hover:bg-primaryDarker transition-all">
    //           ለእርስዎ የሚስማማ የምግብ እቅድ ይመልከቱ
    //         </Link>
    //         <button className="border-gray-600 w-full md:w-[50%] border-2 p-2 px-4 md:px-6 lg:px-8 rounded-full text-gray-900 font-medium text-xs md:text-sm lg:text-base hover:bg-primaryDarker hover:text-white hover:border-primaryLight transition-all">
    //           ፈጣን ግዢ
    //         </button>
    //       </div>
    //     </div>
    //     <div className="flex-1 flex justify-center items-center">
    //       <img className="w-full sm:w-[35rem] max-h-[25rem] sm:max-h-full object-cover" src={bg} alt="" />
    //     </div>
    //   </div>
    // </div>
  );
}

export default Home