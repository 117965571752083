import OrderInfo from './containers/order_info_container';
import Routes from './routes/routes';

function App() {
  return (
    <div>
      <Routes/>
    </div>
  );
}

export default App;
