import React, { useEffect } from 'react'
import NavigationBars from '../components/common/Navigation Bars/NavigationBars'
import MealPrepContainer from '../containers/meal_prepContainer'
import { useTranslation } from 'react-i18next'

const MealPrep = () => {
  const { t } = useTranslation()
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <>
    <NavigationBars isShop={true}/>
    <div className='h-full w-full absolute -z-50 top-52 p-1 flex-col flex justify-center items-cehter'>
      <h1 className='text-center font-bold text-2xl text-gray-600'>{t('prepare')}</h1>
      <h1 className='text-center font-light text-base text-gray-600'>{t('prepareDesc')}</h1>
      <MealPrepContainer/>
    </div>
    </>
  )
}

export default MealPrep