export const addressSuggestions = [
    "Abacoran Sefer",
    "Abadina Area",
    "Abenet",
    "Addis Ketema",
    "Addis Seffer",
    "Addisu Gebeya",
    "Agusta",
    "Akaki-Kality",
    "Alem Bank",
    "Amanuel Area",
    "Ambassador",
    "American Gibi",
    "Arat Kilo",
    "Aroge Kera",
    "Aserasement",
    "Asko Area",
    "Asko Bercheko Faberika Area",
    "Atekelet Tera",
    "Atena Tera",
    "Autobus Tera",
    "Aware",
    "Ayat",
    "Ayertena",
    "Beg Tera",
    "Beherawi",
    "Bekelo Bet",
    "Berberee Berenda",
    "Besrat Gebriel",
    "Biss Meberat",
    "Bole",
    "Bole Ayat",
    "Bole Mikael",
    "Bomb Tera",
    "Bulgariya Mazoriya",
    "Bethel",
    "Ched Tera",
    "Chew Berenda",
    "Chid Tera",
    "Cinima Ras",
    "Coca",
    "Darmar",
    "Datsun Sefer",
    "Doro Manekiya",
    "Doro Tera",
    "Dubai Tera",
    "Enderase",
    "Enqulal Faberika",
    "Eri Bekentu",
    "Ferash Tera",
    "Gedam Seffer",
    "Geja Seffer",
    "Gerji",
    "Giorgis",
    "Gojjam Berenda",
    "Golla Mikael",
    "Goma Kuteba",
    "Goma Tera",
    "Gotera",
    "Habete Giorgis",
    "Hadere Sefer",
    "Jemo",
    "Jos Hanssen",
    "Kara Alo",
    "Kazanchis",
    "Kebena",
    "Kera",
    "Ketena Hulet",
    "Kirkos",
    "Kolfe Keranio",
    "Kolfe Keranyo",
    "Kolfe Tiwan",
    "Korech Tera",
    "Kotebe",
    "La Gare (Legahar)",
    "Lafto",
    "Lancha",
    "Lebu",
    "Leg Taffo",
    "Lekwuanda",
    "Lideta",
    "Mechare Meda",
    "Medhanialem",
    "Megenagna",
    "Mekanisa",
    "Mekanisa Abo",
    "Menahereya Kazanchis",
    "Mennen",
    "Mentaf Tera",
    "Mesalemiya",
    "Meshuwalekiya",
    "Meskel Flower",
    "Mesob Tera",
    "Mexico",
    "Military Tera",
    "Minalesh Tera",
    "Mobil",
    "Molla Maru",
    "Nefas Silk-Lafto",
    "Olympia",
    "Paster",
    "Piassa (piazza)",
    "Posta Bet",
    "Quasmeda",
    "Ras Mekonnen Deldey",
    "Repi",
    "Riche;Embuway Mesk Adarash Bowling",
    "Rufael",
    "Ruwanda",
    "Sarbet",
    "Saris",
    "Saris Abo",
    "Saten Tera",
    "Sebara Babur",
    "Sebategna",
    "Sehan Tera",
    "Semien Mezegaja",
    "Sengatera",
    "Serategna Sefer",
    "Shanta Tera",
    "Sheh Ojele (Shegole",
    "Shekela Tera",
    "Shema Tera",
    "Shera Tera",
    "Shero Meda",
    "Shola",
    "Sidamo Tera",
    "Somale Tera",
    "Soste Kuter Mazoria (Total)",
    "Sunshine Real state",
    "Taliyan Sefer",
    "Tafo Condominium",
    "Tekelehaymanot",
    "Tor Haylloch",
    "Urael",
    "Vatican",
    "Webe Berha",
    "Wello Sefer",
    "Werk Tera",
    "Winget",
    "Yeka",
    "Yeka Abado",
    "Yohannes",
    "Zenebework",
    "4 Kilo",
    "5 Kilo",
    "6 Kilo",
    "22 Golagul",
  ];