// Icon.js
import { RiHome3Line, RiSettings3Line,RiPhoneFill,RiMailFill,RiDeleteBack2Line } from 'react-icons/ri';
import { FaSearch, FaTimes,FaWeight, FaShoppingCart, FaPaperPlane} from 'react-icons/fa';
import { BiCopy, BiFork} from 'react-icons/bi';
import { BsInstagram, BsTelegram, BsTiktok} from 'react-icons/bs';
import { GiWeightLiftingUp} from 'react-icons/gi';
import { MdOutlineError} from 'react-icons/md';
import { AiOutlineUser,AiOutlineCalendar, AiOutlineShoppingCart,AiOutlineDownload, AiOutlineClear, AiOutlineCheckCircle } from 'react-icons/ai';

export const HomeIcon = RiHome3Line;
export const SettingsIcon = RiSettings3Line;
export const UserIcon = AiOutlineUser;
export const SearchIcon = FaSearch;
export const CloseIcon = FaTimes;
export const ShopCart = AiOutlineShoppingCart;
export const PhoneIcon = RiPhoneFill;
export const MailIcon = RiMailFill;
export const AddCart = FaShoppingCart;
export const ClearIcon = AiOutlineClear;
export const DeleteIcon = RiDeleteBack2Line;
export const SendIcon = FaPaperPlane;
export const CancelIcon = RiDeleteBack2Line;
export const SuccessIcon = AiOutlineCheckCircle;
export const CopyIcon = BiCopy;
export const TelegramIcon = BsTelegram;
export const TikTokIcon = BsTiktok;
export const InstagramIcon = BsInstagram;
export const DownloadIcon = AiOutlineDownload;
export const CalendarIcon =  AiOutlineCalendar;
export const FoodIcon =  BiFork;
export const WeightLoss =  FaWeight;
export const WeightGain =  GiWeightLiftingUp;
export const ErrorIcon = MdOutlineError
