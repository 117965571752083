import React, { useEffect } from 'react'
import MealsContainer from '../containers/meals_container'
import NavigationBars from '../components/common/Navigation Bars/NavigationBars'
import { useTranslation } from 'react-i18next'

const MealPlan = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <NavigationBars isShop={true} />
      <div className='w-fit absolute -z-50 top-32 left-0  sm:left-24 flex-col flex justify-center items-start'>
        <h1 className='text-start font-bold text-2xl text-gray-600 ml-8'>{t('find')}</h1>
        <h1 className='text-start font-light text-xl text-gray-600 ml-8'>{t('findDesc')}</h1>
        <MealsContainer />
      </div>
    </>
  )
}

export default MealPlan