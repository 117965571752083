import React, { useEffect } from "react";
import SelectionBox from "../components/feature/selection_checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSubscription,
  subscriptionContent,
  subscriptionPrice,
  subscriptionSchedule,
} from "../redux/actions";
import { CalendarIcon, ClearIcon, FoodIcon } from "../assets/icons/Icons";
import { calculateTotalCost } from "../utils/priceCalculator";
import { useTranslation } from "react-i18next";

const SubscriptionContainer = ({ isPrep, mealInfo }) => {
  const subscriptionSch = useSelector(
    (state) => state.subscription.subscriptionSchedule
  );
  const subscriptionCont = useSelector(
    (state) => state.subscription.subscriptionContent
  );
  const subPrice = useSelector((state) => state.subscription.totalCost);
  const isSelected = useSelector((state) => state.subscription.isSelected);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalCost = calculateTotalCost(
    isSelected,
    subscriptionCont,
    subscriptionSch,
    mealInfo.price
  );

  useEffect(() => {
    dispatch(subscriptionPrice(totalCost, subscriptionSch));
  }, [dispatch, subscriptionSch, totalCost]);

  const handleScheduleSelection = (type) => {
    dispatch(subscriptionSchedule(type));
  };

  const handleContentSelection = (type) => {
    dispatch(subscriptionContent(type));
  };

  const handleClearSub = () => {
    dispatch(clearSubscription());
  };

  return !isPrep ? (
    <div>
      <div className="flex w-full md:w-2/3 my-5 justify-between">
        {["1 Week", "2 Weeks", "1 Month", "3 Months"].map((schedule) => (
          <SelectionBox
            key={schedule}
            Icon={<CalendarIcon />}
            Selected={subscriptionSch === schedule}
            PlanName={
              schedule === "1 Week"
                ? t("1week")
                : schedule === "2 Weeks"
                ? t("2weeks")
                : schedule === "1 Month"
                ? t("1month")
                : schedule === "3 Months" && t("3months")
            }
            OnClick={() => handleScheduleSelection(schedule)}
          />
        ))}
      </div>

      {isSelected && (
        <div className="flex flex-col w-full my-5">
          {subscriptionSch === "1 Week" ? (
            <div className="flex flex-wrap my-5 justify-start">
              {[
                "2 meals perday",
                "3 meals perday",
                "4 meals perday",
                "5 meals perday",
              ].map((content) => (
                <SelectionBox
                  key={content}
                  Selected={subscriptionCont === content}
                  Icon={<FoodIcon />}
                  PlanName={
                    content === "2 meals perday"
                      ? t("2meals")
                      : content === "3 meals perday"
                      ? t("3meals")
                      : content === "4 meals perday"
                      ? t("4meals")
                      : content === "5 meals perday" && t("5meals")
                  }
                  OnClick={() => handleContentSelection(content)}
                />
              ))}
            </div>
          ) : subscriptionSch === "2 Weeks" ? (
            <div className="flex flex-wrap my-5 justify-start">
              {/* Content Selection Boxes */}
              {[
                "2 meals perday",
                "3 meals perday",
                "4 meals perday",
                "5 meals perday",
              ].map((content) => (
                <SelectionBox
                  key={content}
                  Selected={subscriptionCont === content}
                  Icon={<FoodIcon />}
                  PlanName={
                    content === "2 meals perday"
                      ? t("2meals")
                      : content === "3 meals perday"
                      ? t("3meals")
                      : content === "4 meals perday"
                      ? t("4meals")
                      : content === "5 meals perday" && t("5meals")
                  }
                  OnClick={() => handleContentSelection(content)}
                />
              ))}
            </div>
          ) : subscriptionSch === "1 Month" ? (
            <div>
              <p className="font-bold text-primaryDarker ml-1">
                {t("5discount")}
              </p>
              <div className="flex flex-wrap my-5 justify-start">
                {[
                  "1 meal perday",
                  "2 meals perday",
                  "3 meals perday",
                  "4 meals perday",
                  "5 meals perday",
                ].map((content) => (
                  <SelectionBox
                    key={content}
                    Selected={subscriptionCont === content}
                    Icon={<FoodIcon />}
                    PlanName={
                      content === "1 meal perday"
                        ? t("1meal")
                        : content === "2 meals perday"
                        ? t("2meals")
                        : content === "3 meals perday"
                        ? t("3meals")
                        : content === "4 meals perday"
                        ? t("4meals")
                        : content === "5 meals perday" && t("5meals")
                    }
                    OnClick={() => handleContentSelection(content)}
                  />
                ))}
              </div>
            </div>
          ) : (
            subscriptionSch === "3 Months" && (
              <div>
                <p className="font-bold text-primaryDarker ml-1">
                  {t("10discount")}
                </p>
                <div className="flex flex-wrap my-5 justify-start">
                  {[
                    "1 meal perday",
                    "2 meals perday",
                    "3 meals perday",
                    "4 meals perday",
                    "5 meals perday",
                  ].map((content) => (
                    <SelectionBox
                      key={content}
                      Selected={subscriptionCont === content}
                      Icon={<FoodIcon />}
                      PlanName={
                        content === "1 meal perday"
                          ? t("1meal")
                          : content === "2 meals perday"
                          ? t("2meals")
                          : content === "3 meals perday"
                          ? t("3meals")
                          : content === "4 meals perday"
                          ? t("4meals")
                          : content === "5 meals perday" && t("5meals")
                      }
                      OnClick={() => handleContentSelection(content)}
                    />
                  ))}
                </div>
              </div>
            )
          )}
          <div className="flex-col flex gap-2 items-start w-fit">
            <div
              onClick={() => handleClearSub()}
              className="flex items-center gap-2 text-red-600 cursor-pointer hover:text-red-800"
            >
              <ClearIcon />
              <p>{t("clearSelection")}</p>
            </div>
            {subscriptionCont !== "" && (
              <p className="text-slate-800 text-lg">
                <span className="font-bold">{t("totalCost")}: </span>
                {subPrice.toLocaleString()}
                <span className="font-bold text-sm"> {t("birr")}</span>
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <>
      <SelectionBox
        key={"1 Month"}
        Icon={<CalendarIcon />}
        Selected={subscriptionSch === "1 Month"}
        PlanName={t("1month")}
        OnClick={() => handleScheduleSelection("1 Month")}
      />

      <div>
        <p className="font-bold text-primaryDarker ml-1">{t("5discount")}</p>
        {subscriptionSch === "1 Month" && (
          <div className="flex flex-wrap my-5 justify-start">
            {[
              "1 meal per day",
              "2 meals per day",
              "3 meals per day",
              "4 meals per day",
              "5 meals per day",
            ].map((content) => (
              <SelectionBox
                key={content}
                Selected={subscriptionCont === content}
                Icon={<FoodIcon />}
                PlanName={
                  content === "1 meal per day"
                    ? t("1meal")
                    : content === "2 meals per day"
                    ? t("2meals")
                    : content === "3 meals per day"
                    ? t("3meals")
                    : content === "4 meals per day"
                    ? t("4meals")
                    : content === "5 meals per day" && t("5meals")
                }
                OnClick={() => handleContentSelection(content)}
              />
            ))}
          </div>
        )}
      </div>

      <div className="flex-col flex gap-2 items-start w-fit">
        {subscriptionSch === "1 Month" && (
          <div
            onClick={() => handleClearSub()}
            className="flex items-center gap-2 text-red-600 cursor-pointer hover:text-red-800"
          >
            <ClearIcon />
            <p>{t("clearSelection")}</p>
          </div>
        )}
        {subscriptionCont !== "" && (
          <p className="text-slate-800 text-lg">
            <span className="font-bold">{t("totalCost")}: </span>
            {subPrice.toLocaleString()}
            <span className="font-bold text-sm"> {t("birr")}</span>
          </p>
        )}
      </div>
    </>
  );
};

export default SubscriptionContainer;
