import React from 'react'
// import sticker_1 from '../assets/Sticker_1.png'
import howVideo from '../assets/video/how_it_works.mp4'
import { useTranslation } from 'react-i18next'

const HowVideo = () => {
  const { t }=useTranslation()
  return (
    <section className='flex flex-col  md:flex-row p-12 bg-slate-100 rounded-lg m-4 justify-between items-center'>
  <div className='flex-1 md:m-10 flex md:items-start items-center flex-col'>
    <h1 className='font-bold text-center md:text-start text-2xl text-gray-700'>{t('affordableFood')}</h1>
    <div className='w-14 h-1 mt-2 bg-secondary' />
    <p className='font-light text-center md:text-start text-gray-700 my-5'>
     {t('affordableFoodDesc')}
    </p>
  </div>
  <div className='flex-1 flex justify-center rounded-lg'>
    <video className='w-72 h-auto object-cover rounded-lg' controls disablePictureInPicture>
      <source src={howVideo} type="video/mp4" />
    </video>
  </div>
</section>
  )
}

export default HowVideo