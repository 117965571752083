import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import enTranslation from "./locales/en.json";
import amTranslation from "./locales/am.json";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Get language from local storage or fallback to 'en'
const savedLanguage = localStorage.getItem("i18nextLng") || "en";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    am: {
      translation: amTranslation,
    },
  },
  lng: savedLanguage, // Use saved language
  fallbackLng: "en",
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
