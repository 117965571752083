import React from 'react';

const MaedInputs = ({ label, value, length, error, isPhone, onChange, ...props }) => {
    return (
        <div>
            {
                isPhone ?
                    <div>
                        <div className='flex'>
                                <input
                                    type="text"
                                    readOnly
                                    value={'ET +251'}
                                    className={`px-4 mr-1 md:w-1/5 w-1/2 py-1.5 text-sm rounded-md border ${error !== '' ? 'border-red-400' : 'border-gray-400 duration-0'} focus:outline-none focus:ring-1 focus:ring-green-300 focus:border-transparent placeholder-gray-500 text-gray-700 transition duration-300 ring-opacity-0 focus:ring-opacity-100`}
                                    placeholder="Phone-number"
                                />
                        <input
                            type="text"
                            placeholder={label}
                            maxLength={length}
                            className={`px-4 w-full py-1.5 rounded-md border ${error ? 'border-red-400' : 'border-gray-400 duration-0'
                                } focus:outline-none focus:ring-1 focus:ring-green-300 focus:border-transparent placeholder-gray-500 text-gray-700 transition duration-300 ring-opacity-0 focus:ring-opacity-100`}
                            value={value}
                            onChange={onChange}
                            {...props}
                        />
                        </div>
                    </div>
                    :

                    <input
                        type="text"
                        placeholder={label}
                        maxLength={length}
                        className={`px-4 w-full py-1.5 rounded-md border ${error ? 'border-red-400' : 'border-gray-400 duration-0'
                            } focus:outline-none focus:ring-1 focus:ring-green-300 focus:border-transparent placeholder-gray-500 text-gray-700 transition duration-300 ring-opacity-0 focus:ring-opacity-100`}
                        value={value}
                        onChange={onChange}
                        {...props}
                    />
            }
            {error && <p className="text-red-400 text-sm">{error}</p>}

        </div>
    );
};

export default MaedInputs;
