import { useTranslation } from 'react-i18next';
import MealCard from '../components/feature/MealCard'
import { useSelector } from 'react-redux'

const MealsContainer = () => {
  const mealList = useSelector((state) => state.meals.mealList);
  const { t }=useTranslation()
  return (
    <div className='flex flex-wrap justify-center items-start w-full h-screen'>
      <div className='flex flex-wrap w-full'>
        {mealList.map((meal) => (
          <MealCard
            link={`/mealdetail/${meal.id}`}
            meal={meal}
            key={meal.id}
            mealName={t(meal.name)}
            mealDescription={t(meal.shortDesc)}
            image={meal.image[0]}
            price={meal.price}
          />
        ))}
      </div>
    </div>


  )
}

export default MealsContainer