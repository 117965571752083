import React from 'react';
import MobileNavigationBar from '../Navigation Bars/MobileNavigationBar';
import PCNavigationBar from '../Navigation Bars/PCNavigationBar';

const NavigationBars = ({isShop}) => {

  return (
    <div>
    <MobileNavigationBar/> 
    <PCNavigationBar/>
    </div>
  );
};

export default NavigationBars;
