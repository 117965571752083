
export class InputValidator {
    static validateName(name) {
        return name.length > 0 && name.length <= 50;
    }

    static validatePhoneNumber(phoneNumber) {
        const phoneNumberPattern = /^\d+$/;
        return phoneNumber.length === 9 && phoneNumberPattern.test(phoneNumber);
    }

    static validateAddress(address) {
        return address.length > 0;
    }
}
