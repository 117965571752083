//Meal related actions
// export const addOrder = (meal,userId) => ({
//   type: 'ADD_ORDER',
//   payload: { meal,userId }
// })
// export const removeOrder = (itemId) => {
//   return {
//     type: 'REMOVE_ORDER',
//     payload: itemId
//   };
// }

//ORDER RELATED ACTIONS
export const addOrder=(meal)=>({
  type:'ADD_ORDER',
  payload:meal,
})
export const cancelOrder=()=>({
   type:'CANCEL_ORDER'
})
export const sendOrder=(order,fullName,phoneNumber,address,totalPrice)=>({
  type:'SEND_ORDER',
  payload:order,
  fullName:fullName,
  phoneNumber:phoneNumber,
  address:address,
  totalPrice:totalPrice
})
export const orderDone=()=>({
  type:'ORDER_DONE',
})
export const mealPlanSuccess = (mealList) => ({
  type: 'MEAL_PLAN_SUCCESS',
  payload: mealList
});
//SUBSCRIPTION RELATED ACTIONS
export const subscriptionSchedule=(subscriptionSchedule)=>({
  type:'SCHEDULE_SUBSCRIPTION',
  payload:subscriptionSchedule
})
export const subscriptionContent=(subscriptionContent)=>({
  type:'CONTENT_SUBSCRIPTION',
  payload:subscriptionContent
})
export const subscriptionPrice=(totalCost,schedule)=>({
  type:'SUBSCRIPTION_PRICE',
  payload:totalCost,
  schedule:schedule,
})
export const clearSubscription=()=>({
  type:'CLEAR_SUBSCRIPTION',
})
//MEAL PREP ACTIONS
export const addInformation=(info, infoType)=>({
  type:'ADD_INFORMATION',
  payload:info,
  infoType:infoType
})
export const validateInformation=(error)=>({
  type:'VALIDATE_INFORMATION',
  payload:error,
})
export const selectMealPrep=(meal)=>({
  type:'SELECT_MEAL',
  payload:meal
})
export const addMealPrep=(meal)=>({
  type:'ADD_MEAL_PREP',
  payload:meal
})
//USER RELATED ACTIONS
export const loginRequest=()=>({
  type:'LOGIN_REQUEST'
})
export const loginSuccess=(user)=>({
  type:'LOGIN_SUCCESS',
  payload:user
})
export const loginFailure=(error)=>({
  type:'LOGIN_FAILURE',
  payload:error
})
export const logout=()=>({
  type:'LOG_OUT',
})


