import React from 'react';

export function CustomInput(props) {
  return (
    <div
      className={`px-4 md:text-lg text-base py-1.5  rounded-md ${
        props.error && "border-red-500"
      } border border-gray-400 focus:outline-none focus:ring-1 focus:ring-green-300 focus:border-transparent placeholder-gray-400 text-gray-700 transition duration-300 ring-opacity-0 focus:ring-opacity-100`}
    >
      <input
        value={props.target}
        maxLength={6}
        onChange={(e) =>
          props.handleInformation(
            e.target.value.replace(/[^\d.]|(?<=\.)\./g, ""),
            props.type
          )
        }
        type="text"
        className="border-none outline-none"
        placeholder={props.placeholder}
      />
      <span className="font-bold text-base">{props.following}</span>
    </div>
  );
}
