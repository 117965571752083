import { t } from 'i18next';
import React, { useState } from 'react'

const Fasting = ({ selectedDays, setSelectedDays }) => {
    const [isChecked, setIsChecked] = useState(false);
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
      setSelectedDays([]); // Reset selected days when checkbox is toggled
    };
  
    const handleDayChange = (e) => {
      const { value, checked } = e.target;
      setSelectedDays((prevSelectedDays) =>
        checked
          ? [...prevSelectedDays, value] // Add day to selectedDays if checked
          : prevSelectedDays.filter((day) => day !== value) // Remove day from selectedDays if unchecked
      );
    };
  
    return (
      <div>
        <label className="flex items-center w-fit space-x-3 cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              className="sr-only"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <div className="block bg-gray-300 w-10 h-6 rounded-full" />
            <div
              className={`absolute top-0 left-0 bg-primary w-6 h-6 rounded-full transition-transform ${
                isChecked ? "translate-x-full bg-primary" : "bg-slate-400"
              }`}
            ></div>
          </div>
          <span className="text-gray-800 font-medium">{t("fasting")}</span>
        </label>
        {isChecked && (
          <div className="mt-2 text-sm">
            <p className="text-gray-800 font-medium mb-2">{t("selectdays")}:</p>
            <div className="flex gap-2 flex-wrap justify-start">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value="Monday"
                  checked={selectedDays.includes("Monday")}
                  onChange={handleDayChange}
                  className="form-checkbox text-primary h-5 w-5 rounded-sm focus:ring-0 focus:shadow-none"
                />
                <span>{t("monday")}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value="Tuesday"
                  checked={selectedDays.includes("Tuesday")}
                  onChange={handleDayChange}
                  className="form-checkbox text-primary h-5 w-5 rounded-sm focus:ring-0 focus:shadow-none"
                />
                <span>{t("tuesday")}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value="Wednesday"
                  checked={selectedDays.includes("Wednesday")}
                  onChange={handleDayChange}
                  className="form-checkbox text-primary h-5 w-5 rounded-sm focus:ring-0 focus:shadow-none"
                />
                <span>{t("wednesday")}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value="Thursday"
                  checked={selectedDays.includes("Thursday")}
                  onChange={handleDayChange}
                  className="form-checkbox text-primary h-5 w-5 rounded-sm focus:ring-0 focus:shadow-none"
                />
                <span>{t("thursday")}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value="Friday"
                  checked={selectedDays.includes("Friday")}
                  onChange={handleDayChange}
                  className="form-checkbox text-primary h-5 w-5 rounded-sm focus:ring-0 focus:shadow-none"
                />
                <span>{t("friday")}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value="Saturday"
                  checked={selectedDays.includes("Saturday")}
                  onChange={handleDayChange}
                  className="form-checkbox text-primary h-5 w-5 rounded-sm focus:ring-0 focus:shadow-none"
                />
                <span>{t("saturday")}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value="Sunday"
                  checked={selectedDays.includes("Sunday")}
                  onChange={handleDayChange}
                  className="form-checkbox text-primary h-5 w-5 rounded-sm focus:ring-0 focus:shadow-none"
                />
                <span>{t("sunday")}</span>
              </label>
            </div>
          </div>
        )}
      </div>
    );
}

export default Fasting