import axios from "axios";
import { sendOrder } from "../redux/actions";

const SendMealPrepOrderToApi = async (
  fullName, 
  phoneNumber, 
  address, 
  other, 
  days, 
  totalPrice, 
  schedule, 
  content, 
  mealType,
  height,
  weight,
  range,
  target,
  LoadingTrue, 
  LoadingFalse,
  handleError,
  dispatch
  ) => {
  const selectedDays = days.join(',')
  const heightCM=height+'cm'
  const weightKG=weight+'kg'
  const phoneFormatted='+251'+phoneNumber
  const formattedPrice=totalPrice.toLocaleString()
  const orderData = JSON.stringify({
    "Full_Name": fullName,
    "Phone_Number": phoneFormatted,
    "Place_of_Delivery": address,
    "Allergy": other,
    "Fasting_type": selectedDays
  })

  var customer_config = {
    method: "post",
    url: "https://api.maednutritions.com/api/Customer",
    headers: {
      "Content-Type": "application/json",
    },
    data: orderData,
  };
  LoadingTrue();
  try {
    const response = await axios(customer_config)
    
    const mealPrepData = JSON.stringify({
      "Customer_id": response.data,
      "Meal_Type": mealType,
      "Subscription": schedule,
      "Meal_PerDay": content,
      "Total_Price": formattedPrice,
      "Hight":heightCM,
      "Weight":weightKG,
      "Exercise":range,
      "Target":target,
    })

    var sendOrderToApi = {
      method: "post",
      url: "https://api.maednutritions.com/api/Meal_Prep",
      headers: {
        "Content-Type": "application/json",
      },
      data: mealPrepData,
    };

    await axios(sendOrderToApi);
    await dispatch(
      sendOrder(mealType, fullName, phoneNumber, address, totalPrice)
    );
    LoadingFalse();
  } catch (error) {
    LoadingFalse();
    handleError();
  }
}
export default SendMealPrepOrderToApi