import hagerigna1 from './Maed food images optimized/Hagerigna/Hagerigna (1).jpg'
import hagerigna2 from './Maed food images optimized/Hagerigna/Hagerigna (2).jpg'
import hagerigna3 from './Maed food images optimized/Hagerigna/Hagerigna (3).jpg'
import hagerigna4 from './Maed food images optimized/Hagerigna/Hagerigna (4).jpg'
import hagerigna5 from './Maed food images optimized/Hagerigna/Hagerigna (5).jpg'
import hagerigna6 from './Maed food images optimized/Hagerigna/Hagerigna (6).jpg'

import paleo1 from './Maed food images optimized/Paleo/Paleo (1).jpg'
import paleo2 from './Maed food images optimized/Paleo/Paleo (2).jpg'

import highprotein1 from './Maed food images optimized/High Protein/High Protein (1).jpg'
import highprotein2 from './Maed food images optimized/High Protein/High Protein (2).jpg'
import highprotein3 from './Maed food images optimized/High Protein/High Protein (3).jpg'
import highprotein4 from './Maed food images optimized/High Protein/High Protein (4).jpg'
import highprotein5 from './Maed food images optimized/High Protein/High Protein (5).jpg'
import highprotein6 from './Maed food images optimized/High Protein/High Protein (6).jpg'

import keto1 from './Maed food images optimized/Keto/Keto (1).jpg'
import keto2 from './Maed food images optimized/Keto/Keto (2).jpg'

import cleanMeal1 from './Maed food images optimized/Clean Eating/cleanEating (1).jpg'
import cleanMeal2 from './Maed food images optimized/Clean Eating/cleanEating (2).jpg'
import cleanMeal3 from './Maed food images optimized/Clean Eating/cleanEating (3).jpg'

const mealList = [
  {
    id: 1,
    image: [keto1, keto2],
    name: "Keto Meal Plan",
    shortDesc: "ketoShortDesc",
    desc: "ketoDesc",
    price: 268,
    foodContent: [
      { name: "Gram", amount: 250 },
      { name: "Carb", amount: 60 },
      { name: "Protein", amount: 60 },
      { name: "Fat", amount: 60 },
    ],
  },
  {
    id: 2,
    image: [paleo1, paleo2],
    name: "Paleo Meal Plan",
    shortDesc: "paleoMealShorDesc",
    desc: "paleoMealDesc",
    price: 260,
    foodContent: [
      { name: "Gram", amount: 250 },
      { name: "Carb", amount: 60 },
      { name: "Protein", amount: 60 },
      { name: "Fat", amount: 60 },
    ],
  },
  {
    id: 3,
    image: [cleanMeal1, cleanMeal2, cleanMeal3],
    name: "Clean Meal Plan",
    shortDesc: "cleanMealShortDesc",
    desc: "cleanMealDesc",
    price: 248,
    foodContent: [
      { name: "Gram", amount: 250 },
      { name: "Carb", amount: 60 },
      { name: "Protein", amount: 60 },
      { name: "Fat", amount: 60 },
    ],
  },
  {
    id: 4,
    image: [keto1, keto2],
    name: "High Protein Vegan Plan",
    shortDesc: "highProteinVeganShortDesc",
    desc: "highProteinVeganDesc",
    price: 240,
    foodContent: [
      { name: "Gram", amount: 250 },
      { name: "Carb", amount: 60 },
      { name: "Protein", amount: 60 },
      { name: "Fat", amount: 60 },
    ],
  },
  {
    id: 5,
    image: [
      highprotein1,
      highprotein2,
      highprotein3,
      highprotein4,
      highprotein5,
      highprotein6,
    ],
    name: "High Protein Vegeterian Plan",
    shortDesc: "highProteinVegtarianShortDesc",
    desc: "highProteinVegtarianDesc",
    price: 218,
    foodContent: [
      { name: "Gram", amount: 250 },
      { name: "Carb", amount: 60 },
      { name: "Protein", amount: 60 },
      { name: "Fat", amount: 60 },
    ],
  },
  {
    id: 6,
    image: [
      hagerigna1,
      hagerigna2,
      hagerigna3,
      hagerigna4,
      hagerigna5,
      hagerigna6,
    ],
    name: "Hagerigna Meal Plan",
    shortDesc: "hagerignaShortDesc",
    desc: "hagerignaDesc",
    price: 260,
    foodContent: [
      { name: "Gram", amount: 250 },
      { name: "Carb", amount: 60 },
      { name: "Protein", amount: 60 },
      { name: "Fat", amount: 60 },
    ],
  },
];
export default mealList