import React from 'react'

const Card = ({image,title,body}) => {
  return (
  <div className='flex flex-col items-center justify-center bg-slate-100 rounded-xl pb-8 w-full sm:w-84 p-4 sm:p-8'>
  <img src={image} alt="" className='w-[70%] p-0' />
  <div className='h-full flex items-center flex-col justify-start w-full'>
  <h3 className='font-semibold text-2xl text-gray-700 mt-4'>{title}</h3>
  <p className='text-xl w-full font-light text-gray-800 mt-4'>{body}</p>
  </div>
</div>

  )
}

export default Card