import { useSelector } from 'react-redux'
import { WeightGain, WeightLoss } from '../../assets/icons/Icons'
import { useTranslation } from 'react-i18next'

const Form2 = (props) => {
  const target = useSelector(state => state.mealPrep.target)
  const height = useSelector(state => state.mealPrep.height)
  const weight = useSelector(state => state.mealPrep.weight)
  const { t }=useTranslation() 

  return (
    <div className="w-fit h-full p-8">
      {target === "Weight loss" ? (
        <div className="flex items-center flex-col">
          <WeightLoss className="w-14 h-14 text-slate-400" />
          <h1 className="text-center font-light text-2xl text-gray-600">
            {t("weightLoss")}
          </h1>
          <h1 className="text-center font-bold text-2xl text-gray-600">
            {t("weightLossDesc")}
          </h1>
          <h2 className="text-center font-light text-base text-gray-600">
            {t("yourHeight")}
          </h2>
          <p className="text-center font-bold text-xl text-gray-600">
            {height} CM
          </p>
          <h2 className="text-center font-light text-base text-gray-600">
            {t("yourWeight")}
          </h2>
          <p className="text-center font-bold text-xl text-gray-600">
            {weight} KG
          </p>
          <h2 className="text-center font-light text-base text-gray-600">
            {t("yourBMI")}
          </h2>
          <p className="text-center font-bold text-xl text-gray-600">
            {(weight / ((height * height) / 1000)).toFixed(2)}
          </p>
        </div>
      ) : (
        <div className="flex items-center flex-col">
          <WeightGain className="w-14 h-14 text-slate-400" />
          <h1 className="text-center font-light md:text-2xl text-lg text-gray-600">
            {t("weightGain")}
          </h1>
          <h1 className="text-center font-bold md:text-2xl text-lg text-gray-600">
            {t("weightGainDesc")}
          </h1>
          <h2 className="text-center font-light text-base text-gray-600">
            {t("yourHeight")}
          </h2>
          <p className="text-center font-bold text-xl text-gray-600">
            {height} M
          </p>
          <h2 className="text-center font-light text-base text-gray-600">
            {t("yourWeight")}
          </h2>
          <p className="text-center font-bold text-xl text-gray-600">
            {weight} KG
          </p>
          <h2 className="text-center font-light text-base text-gray-600">
            {t("yourBMI")}
          </h2>
          <p className="text-center font-bold text-xl text-gray-600">
            {(weight / (height * height)).toFixed(2)}
          </p>
        </div>
      )}
      <div className="flex w-full justify-between gap-5">
        <button
          className="px-4 md:text-base text-xs w-full h-12 mt-7 py-2 bg-primary text-white font-bold rounded-md hover:bg-primaryDarker transition-all"
          onClick={props.handlePrevStep}
        >
          {t("back")}
        </button>
        <button
          className="px-4 md:text-base text-xs w-full h-12 mt-7 py-2 bg-primary text-white font-bold rounded-md hover:bg-primaryDarker transition-all"
          onClick={props.handleNextStep}
        >
          {t("choose")}
        </button>
      </div>
    </div>
  );
}

export default Form2