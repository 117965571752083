export const calculateTotalCost = (isSelected,subscriptionCont,subscriptionSch,price) => {
    let totalCost = 0;

    if (isSelected) {
        const mealsPerDay = parseInt(subscriptionCont.split(" ")[0]);

        if (subscriptionSch === '1 Week') {
            totalCost = (price*(mealsPerDay)+60+34*mealsPerDay)*6;
        } else if (subscriptionSch === '2 Weeks') {
            totalCost = (price*(mealsPerDay)+60+34*mealsPerDay)*12;
        } else if (subscriptionSch === '1 Month') {
            totalCost = (price*(mealsPerDay)+60+34*mealsPerDay)*26;
        } else if (subscriptionSch === '3 Months') {
            totalCost = (price*(mealsPerDay)+60+34*mealsPerDay)*78;
        }
    }

    return totalCost;
}