import React from 'react';
import { useTranslation } from 'react-i18next';
import enFlag from '../../assets/flags/en.png';
import amFlag from '../../assets/flags/am.png';

const languageOptions = [
  { id: 'en', language: 'english', flag: enFlag },
  { id: 'am', language: 'amharic', flag: amFlag },
  // Add more language options here if needed
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

const changeLanguage = (e) => {
  const selectedLanguage = e.target.value;
  i18n.changeLanguage(selectedLanguage);
  localStorage.setItem("i18nextLng", selectedLanguage); // Manually save language to localStorage
};


  return (
    <div className="flex items-center">
      <div className="relative ml-3">
        <select
          id="language"
          onChange={changeLanguage}
          value={i18n.language} // Set value to current language
          className="appearance-none border outline-none focus:ring-1 ring-secondary px-6 py-1 rounded-md text-slate-800"
        >
          {languageOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {t(option.language)}
            </option>
          ))}
        </select>

        <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
          <span className="w-6 h-6">
            {languageOptions.map((option) => (
              <img
                key={option.id}
                src={option.flag}
                alt={option.language}
                className={`${
                  i18n.language === option.id ? "block" : "hidden"
                } w-full h-full`}
              />
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
