import React from 'react'
import { SuccessIcon } from '../../assets/icons/Icons'
import { useTranslation } from 'react-i18next';

const MealPrepCard = ({ image, mealName, mealDescription, price, Selected }) => {
    const { t } = useTranslation();
    return (
      <div
        className={`sm:w-80 h-1/2 sm:h-[26rem] overflow-hidden rounded-md bg-white border-gray-400 border m-6 hover:cursor-pointer hover:border-primary transition-all ${
          Selected && "border-2xl border-primary opacity-80"
        } rounded-md bg-white border-gray-400 border m-6 hover:cursor-pointer hover:border-primary transition-all`}
      >
        {Selected && (
          <SuccessIcon className="absolute w-10 h-10 text-primary" />
        )}
        <img className="object-cover w-full h-52" src={image} alt="" />
        <div className="p-3">
          <div className="flex pb-2 w-full justify-between">
            <h1 className="font-bold text-gray-800 w-1/2 text-base sm:text-lg">
              {mealName}
            </h1>
            <p className="font-bold text-gray-800 h-fit text-base sm:text-base">
              {price} {t("birr")}/
              <span className="text-gray-600 text-sm font-normal">
                {" "}
                {t("permeal")}
              </span>
            </p>
          </div>
          <p className="text-sm text-gray-500 h-14 sm:h-14 overflow-hidden">
            {mealDescription}
          </p>
          <div></div>
        </div>
      </div>
    );
}

export default MealPrepCard