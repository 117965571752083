import React, { useState } from "react";
import OrderModal from "../components/modals/order_modal";
import Autosuggest from "react-autosuggest";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/logos/Logo_wordmark.png";
import { cancelOrder } from "../redux/actions";
import { CancelIcon, SendIcon } from "../assets/icons/Icons";
import { addressSuggestions } from "../assets/suggestion";
import SendMealPlanOrderToApi from "../controllers/handleMealPlanOrder";
import MaedInputs from "../components/common/MaedInput";
import Fasting from "../components/feature/Fasting";
import SendMealPrepOrderToApi from "../controllers/handleMealPrepOrder";
import { ErrorState, LoadingState } from "../components/State Modals/State";
import { validator } from "./validator";
import { useTranslation } from "react-i18next";

const OrderContainer = ({ isPrep }) => {
  const selectedMeal = useSelector((state) => state.order.order);
  const selectedMealSchedule = useSelector(
    (state) => state.subscription.subscriptionSchedule
  );
  const selectedMealContent = useSelector(
    (state) => state.subscription.subscriptionContent
  );
  const subPrice = useSelector((state) => state.subscription.totalCost);
  const target = useSelector((state) => state.mealPrep.target);
  const height = useSelector((state) => state.mealPrep.height);
  const weight = useSelector((state) => state.mealPrep.weight);
  const range = useSelector((state) => state.mealPrep.rangeValue);
  const isModalOpen = useSelector((state) => state.order.isModalOpen);
  const { t }=useTranslation()
  const [loading, setLoading] = useState(false);
  const [SendingError, setSendingError] = useState(false);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    address: "",
    other: "",
  });
  const [errors, setErrors] = useState({
    nameError: "",
    phoneNumberError: "",
    addressError: "",
  });
  const validateInputs = validator(formData, setErrors);
  const handleCancel = () => {
    dispatch(cancelOrder());
  };
  const handleSuggestionSelected = (event, { suggestion }) => {
    setFormData((data) => ({
      ...data,
      address: suggestion + ",",
    }));
    setSuggestionsVisible(false);
  };

  const LoadingTrue = () => {
    setLoading(true);
  };
  const LoadingFalse = () => {
    setLoading(false);
  };

  const handleError = () => {
    setSendingError(!SendingError);
  };
  const handleOrderSend = () => {
    if (isPrep) {
      if (validateInputs()) {
        if (
          SendMealPrepOrderToApi(
            formData.name,
            formData.phoneNumber,
            formData.address,
            formData.other,
            selectedDays,
            subPrice,
            selectedMealSchedule,
            selectedMealContent,
            selectedMeal.name,
            height,
            weight,
            range,
            target,
            LoadingTrue,
            LoadingFalse,
            handleError,
            dispatch
          )
        ) {
          setFormData({
            name: "",
            phoneNumber: "",
            address: "",
            other: "",
          });
        }
      }
    } else if (validateInputs()) {
      SendMealPlanOrderToApi(
        formData.name,
        formData.phoneNumber,
        formData.address,
        formData.other,
        selectedDays,
        subPrice,
        selectedMealSchedule,
        selectedMealContent,
        selectedMeal.name,
        LoadingTrue,
        LoadingFalse,
        handleError,
        dispatch
      );
      // handleSend(selectedMeal);
    }
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : addressSuggestions.filter(
          (address) =>
            address.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  const getSuggestionValue = (suggestion) => suggestion;
  const renderSuggestion = (suggestion) => (
    <div className="cursor-pointer m-2 p-2 text-slate-800 text-sm font-semibold hover:bg-slate-100">
      {suggestion}
    </div>
  );
  const renderSuggestionsContainer = ({ containerProps, children }) => {
    const showContainer = getSuggestions(formData.address).length > 0;
    return showContainer && suggestionsVisible ? (
      <div
        {...containerProps}
        className="absolute md:left-[37rem]  md:w-[30%] md:top-[63%] top-[69%] md:right-full"
        style={{
          height: "30%",
          overflow: "scroll",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.3)",
          zIndex: 2,
        }}
      >
        {children}
      </div>
    ) : null;
  };
  const inputStyle = {
    padding: "6px",
    border: errors.addressError ? "1px solid #DC2626" : "1px solid #9CA3AF", // Change border color if there's an error
    borderRadius: "5px",
    fontSize: "16px",
    width: "100%",
    outline: "none",
  };
  const inputProps = {
    placeholder: t('address'),
    value: formData.address,
    onChange: (e, { newValue, method }) => {
      setFormData((data) => ({
        ...data,
        address: newValue,
      }));

      if (method === "type") {
        setSuggestionsVisible(true);
      } else if (method === "click") {
        setSuggestionsVisible(false);
      }
    },
    style: inputStyle,
  };

  return (
    isModalOpen && (
      <>
        .
        {loading ? (
          <LoadingState />
        ) : SendingError ? (
          <ErrorState retry={handleError} />
        ) : (
          <OrderModal>
            <div className="flex justify-center w-full ">
              <img className="w-24 mb-4" src={logo} alt="" />
            </div>
            <h1 className="font-light text-gray-600 text-lg">
              {t("selectpackage")}
            </h1>
            <h1 className="font-bold text-gray-800 text-2xl capitalize">
              {t(selectedMeal.name)}
            </h1>
            <div className="w-full flex md:flex-row flex-col p-2 ">
              <div className="md:w-1/2 w-full">
                <div className="w-14 h-1 my-2 bg-secondary" />
                <div className="w-1/2 rounded-lg my-2">
                  {selectedMeal.image != null && (
                    <img
                      className="w-1/2 object-cover rounded-lg"
                      src={selectedMeal.image[0]}
                      alt=""
                    />
                  )}
                </div>
                <h1 className="font-normal text-slate-800 text-lg capitalize mb-2">
                  {t("for")} |
                  <span className="font-bold text-slate-800">
                    {" "}
                    {selectedMealSchedule === "1 Week"
                      ? t("1week")
                      : selectedMealSchedule === "2 Weeks"
                      ? t("2weeks")
                      : selectedMealSchedule === "1 Month"
                      ? t("1month")
                      : selectedMealSchedule === "3 Months" && t("3months")}
                  </span>
                </h1>
                <h1 className="font-bold text-slate-800 text-lg capitalize mb-2">
                  {selectedMealContent === "1 meal perday"
                    ? t("1meal")
                    : selectedMealContent === "2 meals perday"
                    ? t("2meals")
                    : selectedMealContent === "3 meals perday"
                    ? t("3meals")
                    : selectedMealContent === "4 meals perday"
                    ? t("4meals")
                    : selectedMealContent === "5 meals perday" && t("5meals")}
                </h1>
                <h1 className="font-normal text-slate-800 text-lg capitalize mb-2">
                  {t("onemealprice")} |
                  <span className="font-bold text-slate-900">
                    {" "}
                    {selectedMeal.price.toLocaleString()} {t("birr")}
                  </span>
                </h1>
                <h1 className="font-normal text-slate-800 text-lg capitalize mb-2">
                  {t("totalCost")} |
                  <span className="font-bold text-slate-900">
                    {" "}
                    {subPrice.toLocaleString()} {t("birr")}
                  </span>
                </h1>
              </div>
              <div className="md:w-2/3 w-full flex flex-col gap-6">
                <p className="font-bold text-gray-800 text-lg capitalize">
                  {t("fillForm")}
                </p>
                <MaedInputs
                  label={t("fullname")}
                  value={formData.name}
                  error={errors.nameError}
                  onChange={(e) =>
                    setFormData((data) => ({
                      ...data,
                      name: e.target.value,
                    }))
                  }
                />
                <MaedInputs
                  label={t("phonenumber")}
                  value={formData.phoneNumber}
                  error={errors.phoneNumberError}
                  isPhone={true}
                  length={9}
                  onChange={(e) =>
                    setFormData((data) => ({
                      ...data,
                      phoneNumber: e.target.value.replace(/\D/g, ""),
                    }))
                  }
                />

                <div>
                  <Autosuggest
                    suggestions={getSuggestions(formData.address)}
                    onSuggestionsFetchRequested={() => {}}
                    onSuggestionsClearRequested={() => {}}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    inputProps={inputProps}
                    onSuggestionSelected={handleSuggestionSelected}
                  />
                  <p className="text-red-400 text-sm">{errors.addressError}</p>
                </div>
                <span className="flex items-center gap-2 justify-start">
                  <MaedInputs
                    type="text"
                    placeholder={t("otherinformation")}
                    value={formData.other}
                    onChange={(e) =>
                      setFormData((data) => ({
                        ...data,
                        other: e.target.value,
                      }))
                    }
                  />
                  <p className="text-red-600 text-base">{t("allergies")}*</p>
                </span>
                <div>
                  <Fasting
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-6 mt-4">
              <button
                onClick={() => handleCancel()}
                className="w-3/4 md:text-base text-xs border text-slate-600 hover:text-white border-red-600 hover:bg-red-700 rounded-md p-3 px-3 font-bold  transition-all duration-100 flex items-center justify-center gap-3"
              >
                <CancelIcon /> {t("cancel")}
              </button>
              <button
                onClick={() => {
                  if (validateInputs) {
                    handleOrderSend(selectedMeal);
                  }
                }}
                className={`w-3/4 md:py-3 py-1 md:text-base text-xs ${
                  validateInputs
                    ? "bg-primary hover:bg-primaryDarker"
                    : "bg-slate-400 hover:bg-slate-500"
                } rounded-md p-2 text-base px-3 text-white transition-all duration-100 flex items-center justify-center gap-3`}
              >
                {t("sendyourorder")}
                <SendIcon />
              </button>
            </div>
          </OrderModal>
        )}
      </>
    )
  );
};

export default OrderContainer;
