import React from 'react'
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Home from '../containers/home_container'
import MealPlan from '../pages/meal_plan'
import MealPrep from '../pages/meal_prep'
import MealDetail from '../pages/meal_detail'
import MealPrepDetail from '../pages/mealPrep_detail'
const MyRoutes = () => {
  return (
    <Router>
        <Routes>
            <Route exact path='/' Component={Home}/>
            <Route exact path='/mealprep' Component={MealPrep}/>
            <Route exact path='/mealplan' Component={MealPlan}/>
            <Route exact path='/mealdetail/:mealID' Component={MealDetail}/>
            <Route exact path='/mealPrepdetail/:mealID' Component={MealPrepDetail}/>
        </Routes>
    </Router>
  )
}

export default MyRoutes