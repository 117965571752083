import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import MealDetailImageSlideShow from '../components/common/MealDetailImageSlideShow'
import MealInfo from '../containers/MealInfo'
import NavigationBars from '../components/common/Navigation Bars/NavigationBars'
import OrderContainer from '../containers/order_container'
import OrderInfo from '../containers/order_info_container'

const MealPrepDetail = () => {
  const { mealID } = useParams()
  const mealList = useSelector((state) => state.meals.mealList)
  const selectedMeal = mealList.find((meal) => meal.id === parseInt(mealID))

  return (
    <>
      <NavigationBars />
      <OrderContainer isPrep={true} />
      <OrderInfo />
      <div className=" md:block md:mt-5 mt-48 ">
        <div className="flex flex-col  items-center justify-center md:flex-row h-screen">
          <div className="w-full flex-1 flex items-center justify-center md:w-1/2">
            <MealDetailImageSlideShow images={selectedMeal.image} />
          </div>
          <div className="w-full p-2 flex-1 flex h-2/3 items-start md:w-1/2">
            <MealInfo isPrep={true} mealInfo={selectedMeal} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MealPrepDetail;
