import React from 'react'
import How from '../pages/how';
import Home from '../pages/home';
import GetItNow from '../pages/get_it';
import Footer from '../components/common/footer';
import NavigationBars from '../components/common/Navigation Bars/NavigationBars';
import HowDesctiption from '../pages/how_description';
const Pages_container = () => {
  return (
    <div>
      <NavigationBars />
    <div className='top-16  h-screen absolute -z-50 w-full'>
        <Home />
        <How />
        <HowDesctiption/>
        <GetItNow/>
        <Footer/>
    </div>
    </div>
  )
}

export default Pages_container