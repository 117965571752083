import React from "react";
import OrderModal from "../modals/order_modal";
import { ErrorIcon } from "../../assets/icons/Icons";

export const LoadingState = () => {
  return (
    <OrderModal>
      <div className="w-full p-5 flex flex-col items-center justify-center gap-5">
        <div className="load w-full h-full flex items-center justify-center">
          <span className="span"></span>
          <span className="span"></span>
          <span className="span"></span>
          <span className="span"></span>
        </div>
        <div className="w-full flex flex-col items-center">
          <h1 className="font-bold text-slate-800">
            WE ARE SENDING YOUR ORDER
          </h1>
          <p className="font-normal text-sm text-slate-800">
            Your order is being processed, please hold on!
          </p>
        </div>
      </div>
    </OrderModal>
  );
};

export const ErrorState = ({retry}) => {
  return (
    <>
      <OrderModal>
        <div className="flex gap-5 flex-col items-center p-4 text-slate-800">
          <ErrorIcon className="text-5xl text-secondary" />
          <div className="flex items-center flex-col">
            <h1 className="text-xl font-semibold">Something went wrong</h1>
            <p className="text-sm">
              There was a problem while delivering your order!
            </p>
          </div>
          <button onClick={retry} className="bg-primary text-white rounded-md p-2 w-1/2">Try Again</button>
        </div>
      </OrderModal>
    </>
  );
};
export const SuccessState = () => {
  return <></>;
};
