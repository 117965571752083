import React from 'react'
import Card from '../components/feature/card'
import foodChoice from '../assets/food choice.png'
import foodDelivery from '../assets/food delivery.png'
import foodExperts from '../assets/food experts.png'
import { useTranslation } from 'react-i18next'
const Cards_container = () => {
    const { t }= useTranslation()
    const how_it_works = [
        { id:1, image: foodChoice, title: t("choice"), body: t("choiceDesc") },
        { id:2,image: foodExperts, title: t("foodExpert"), body: t("foodExpertDesc") },
        { id:3,image: foodDelivery, title: t("foodDelivery"), body: t("foodDeliveryDesc") },
    ]
    return (
        <div className='flex w-full flex-wrap md:flex-nowrap mt-3 justify-center gap-5'>
            {how_it_works.map((how) => (
                <Card key={how.id} image={how.image} title={how.title} body={how.body} />
            ))}
        </div>
    )
}

export default Cards_container