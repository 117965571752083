import React, { useState } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import Form from './forms/form_1';
import Form2 from './forms/form_2';
import Form3 from './forms/form_3';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addMealPrep } from '../redux/actions';


const MealPrepContainer = () => {
  const [activeStep, setActiveStep] = useState(0);
  const selectedMeal = useSelector(state => state.mealPrep.meal)
  const target = useSelector(state => state.mealPrep.target)
  const height = useSelector(state => state.mealPrep.height)
  const weight = useSelector(state => state.mealPrep.weight)
  const range = useSelector(state => state.mealPrep.rangeValue)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    alert(`Order Details:
    Target: ${target}
    Height: ${height}
    Weight: ${weight}
    Selected Meal: ${selectedMeal.name}
    Range: ${range}`);
    dispatch(addMealPrep)
  };

  return (
    <div className='w-full h-full flex flex-col items-center pt-4'>
      <Stepper styleConfig={{ activeBgColor: '#04ab05', completedBgColor: "#fadb0d", completedTextColor: '#000' }} className='text-primary w-full' activeStep={activeStep} onStepChange={handleStepChange}>
        <Step label={t('step1')} onClick={() => handleStepChange(0)}>
          1
        </Step>
        <Step label={t('step2')} onClick={() => handleStepChange(1)}>
          2
        </Step>
        <Step label={t('step3')} onClick={() => handleStepChange(2)}>
          3
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <div>
          <Form handleNextStep={handleNextStep} />
        </div>
      )}
      {activeStep === 1 && (
        <div>
          <Form2 handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} />
        </div>
      )}

      {activeStep === 2 && (
        <div>
          <Form3 handleSubmit={handleSubmit} handlePrevStep={handlePrevStep} />
        </div>
      )}
    </div>
  );
};

export default MealPrepContainer;
