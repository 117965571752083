import React from 'react'
import { AddCart } from '../assets/icons/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { addOrder } from '../redux/actions'
import SubscriptionContainer from './subscription_container'
import { useTranslation } from 'react-i18next'

const MealInfo = ({ mealInfo, isPrep }) => {
    const selectedMealSchedule = useSelector(state => state.subscription.subscriptionSchedule);
    const selectedMealContent = useSelector(state => state.subscription.subscriptionContent);
    // const sentOrder=useSelector(state=>state.order.sentOrder);
    const { t } = useTranslation();
    const dispatch=useDispatch()
    const handleOrder=(meal)=>{
     dispatch(addOrder(meal))
     console.log(meal)
    }
    return (
      <div className="w-full h-full flex flex-col justify-between capitalize">
        <div>
          <h1 className="font-bold text-slate-800 text-3xl">{t(mealInfo.name)}</h1>
          <div className="w-14 h-1 mt-2 bg-secondary" />
          <p className="font-light mt-4 text-slate-600 text-lg">
            {t(mealInfo.desc)}
          </p>
        </div>
        <div></div>
        <div>
          <h1 className="font-bold text-slate-800 text-xl mt-6">
            {t("subTitle")}
          </h1>
          <SubscriptionContainer mealInfo={mealInfo} isPrep={isPrep} />
          <h1 className="font-bold my-5 text-slate-800 text-2xl">
            {mealInfo.price.toLocaleString()}{" "}
            <span className="text-lg">{t("birr")} </span>
            <span className="text-slate-600 font-normal text-lg">
              | {t("permeal")}
            </span>
          </h1>
          <button
            onClick={() => {
              if (selectedMealContent !== "" && selectedMealSchedule !== "") {
                handleOrder(mealInfo);
              }
            }}
            className={`w-full mb-3 flex rounded-md ${
              selectedMealContent !== "" && selectedMealSchedule !== ""
                ? "bg-primary hover:bg-primaryDarker"
                : "bg-slate-400 hover:bg-slate-500"
            } p-3 items-center justify-center gap-5  transition-all text-white font-bold`}
          >
            {t("ordernow")}
            <AddCart />
          </button>
        </div>
      </div>
    );
}

export default MealInfo